import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Container, Typography, alpha, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';
import BackendAccVendorLevel1Table from '../BackendAccVendorLevel1Table';
import { getAllVendorApprovedRequests } from 'src/services/vendorAcc';
import { useParams, useRouter } from 'src/routes/hooks';
import CommonNavigation from 'src/components/custom/CommonNavigation';
import { useSnackbar } from 'src/components/snackbar';
import { HasAccess, usePermify } from '@permify/react-role';


export default function BackendAccVendorLevel1View() {
  const settings = useSettingsContext();
  const { id } = useParams();
  const router = useRouter();
  const { isAuthorized, isLoading } = usePermify();
  const [backendAuth, setBackendAuth] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [vendorData, setVendorData] = useState([]);
  const [projectData, setProjectData] = useState({});
  useEffect(() => {
    getAllVendorApprovedRequests(id).then((res) => {
      if (res.status === 404) {
        enqueueSnackbar('No Line Item avaliable');
      } else {
        setProjectData(res.project);
        setVendorData(res.vendors);
      }
    });
    const callAuth = async() => {
      let tempAuth = await isAuthorized([],"backend");
      await setBackendAuth(tempAuth)
    }
  callAuth();
  }, []);
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      {/* <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <CustomBreadcrumbs
          heading="BackEnd: ACC. for Vendor - Level 1"
          links={[
            { name: 'Home', href: '/home' },
            {
              name: 'Quotes',
              href: '/home/quote',
            },
            {
              name: 'Vendor',
              href: `/quote/${id}/vendor`,
            },
            {
              name: 'BackEnd: ACC. for Vendor - Level 2',
              href: `/acc-vendor/l2/${id}`,
            },
            {
              name: 'BackEnd: ACC. for Vendor - Level 1',
              href: `/acc-vendor/l1/${id}`,
            },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Button
              href={`/accounting-client-level1/${id}`}
              style={{ borderRadius: 1, width: 190 }}
              variant="outlined"
            >
              <Typography textTransform="uppercase" variant="button" b>
                Accounting Client Level 1
              </Typography>
            </Button>
            <Button
              href={`/accounting-client-level2/${id}`}
              style={{ borderRadius: 1, width: 190 }}
              variant="outlined"
            >
              <Typography textTransform="uppercase" variant="button" b>
                Accounting Client Level 2
              </Typography>
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            marginTop="10px"
          >
            <Button
              href={`/frontend-acc-client/${id}`}
              style={{ borderRadius: 1, width: 180, marginRight: 6 }}
              variant="outlined"
            >
              <Typography textTransform="uppercase" variant="button" b>
                Client Frontend
              </Typography>
            </Button>
            <Button
              href={`/acc-vendor/l2/${id}`}
              style={{ borderRadius: 1, width: 190 }}
              variant="outlined"
            >
              <Typography textTransform="uppercase" variant="button" b>
                Accounting Vendor Level 2
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
        <CommonNavigation selectedTab="vendor" />
        <Typography textTransform="uppercase" variant="h6">
          CURRENCY - {projectData?.currency?.symbol} USD
        </Typography>
      </Box>
      <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: 1,
              paddingLeft: 4,
              paddingRight: 4,
              paddingTop: 3,
              paddingBottom: 3,
              borderRadius: 2,
              mt: 2,
              mb: 2,
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
              border: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            <Box>
              <Chip
                style={{ textTransform: 'uppercase' }}
                sx={{ borderRadius: 0, mr: 1 }}
                label={`Project ID: ${projectData?.projectId}`}
              />
              <Chip
                style={{ textTransform: 'uppercase' }}
                sx={{ borderRadius: 0, mr: 1 }}
                label={`RFQ: ${projectData?.RFQ}`}
                variant="outlined"
              />
            </Box>
            <Typography textTransform="uppercase" variant="caption">
              {'Recently edited by - {User Name}'}
            </Typography>
          </Box> */}
          <Box display="flex">
        <Box sx={{ width: '100%'}}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box
              sx={{ width: '50%', borderRight: '1px solid white', pr: 3, mb: 2 }}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box>
                <CustomBreadcrumbs
                  heading="ACCOUNTING VENDOR"
                  links={[
                    { name: 'Home', href: '/home' },
                    { name: 'ACCOUNTING LIST', href: '/home/accounting' },
                    { name: 'ACCOUNTING VENDOR Level 1', href: '/home' },
                  ]}
                  sx={{mb:2, color: 'white' }}
                />
                <Stack>
                  <Stack sx={{ mb:2 }} direction={'row'} justifyContent={'space-between'}>
                  <Chip
                      textTransform="uppercase"
                      label={`PROJECT ID : ${projectData?.projectId}`}
                      style={{ background: '#71ccd9', width: '100%' }}
                    />
                  </Stack>
                  <Stack direction={'row'} justifyContent={'space-between'}>

                  <Typography
                    sx={{ color: 'white' }}
                    variant="span"
                    gutterBottom
                    textTransform="uppercase"
                  >
                    CURRENCY : {projectData?.currency?.symbol} {projectData?.currency?.abbreviation}
                  </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Typography
                sx={{ color: 'white' }}
                variant="h4"
                gutterBottom
                textTransform="uppercase"
              >
                Level 1
              </Typography>
            </Box>
            <Box
              sx={{ width: '60%', pr: 2, pl: 2 }}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box
                sx={{
                  pr: 3,
                  pl: 3,
                  pt: 4,
                  width:'100%',
                  mb:2,
                  color: 'white',
                }}
              >
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '30%' }}>PROJECT ID</Box>:
                  <Box sx={{ width: '70%', pl: 3 }}>{projectData?.projectId}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '30%' }}>RFQ NO.</Box>:
                  <Box sx={{ width: '70%', pl: 3, wordWrap: 'break-word' }}>{projectData?.RFQ}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '30%' }}>RFQ NAME</Box>:
                  <Box sx={{ width: '70%', pl: 3, wordWrap: 'break-word' }}>{projectData?.RFQName}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} >
            <HasAccess
              roles={['admin']}
              permissions={['accounting_frontend_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/frontend-acc-client/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  ml:2,
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_client_backend_level1_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/accounting-client-level1/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting CLIENT L1
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_client_backend_level2_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/accounting-client-level2/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting CLIENT L2
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_vendor_backend_level1_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/acc-vendor/l1/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: '2px solid red'
                }}
              >
                Accounting Vendor L1
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_vendor_backend_level2_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/acc-vendor/l2/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting Vendor L2
              </Button>
            </HasAccess>
            {backendAuth === false ? 
          <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['vendor_home_default_view']}>
            <Button
              disabled={projectData?.isCancelled}
              onClick={() => router.push(`/quote/${id}/vendor`)}
            variant="contained"
            sx={{
              backgroundColor: 'gray',
              borderRadius: '0px',
              marginRight: '10px',
              color: 'black'
            }}
            >
              Vendor HomePage
            </Button>
          </HasAccess>: null}
            {backendAuth === false ?
            <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['delivery_l2']}>
            <Button
             
              href = {`/delivery/deliveryl2/${id}`}
              sx={{
                backgroundColor: 'gray',
                borderRadius: '0px',
                marginRight: '10px',
              }}
            >
              Delivery
            </Button>
          </HasAccess>:null}
          </Box>
        </Box>
      </Box>
      {projectData && projectData?.line_items ? (
        <>
          <BackendAccVendorLevel1Table
            data={vendorData.map((i, index) => ({
              ...i,
              index: index + 1,
              line_item: projectData.line_items?.find((j) => j._id == i.partId),
            }))}
            projectData={projectData}
          />
        </>
      ) : (
        <Typography>NO DATA AVAILABLE FOR PROVIDED PROJECT</Typography>
      )}
    </Container>
  );
}
