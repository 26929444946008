import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Typography,
  CircularProgress,
  Button,
  Backdrop,
  TextField,
  Dialog,
  Card,
  CardContent,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState, useMemo } from 'react';
import Iconify from 'src/components/iconify/iconify';
import { useParams } from 'src/routes/hooks';
import * as Yup from 'yup';
import { useSnackbar } from 'src/components/snackbar';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getQuotedProjectDetails,
  createEshopRequest,
  updateEshopRequest,
  deleteEshopRequest,
  initiateChangeApprovalEshop,
  approvalChangeApprovalEshop,
  getEshopRequestSuggestions,
} from 'src/services/eshop';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useDebounce } from 'src/hooks/use-debounce';
import { HasAccess } from '@permify/react-role';
import { columnMenuStateInitializer } from '@mui/x-data-grid/internals';
import UploadFile from 'src/components/upload/uploadFileButton';
import { useAuthContext } from 'src/auth/hooks';
import { archiveVendorRequestService } from 'src/services/vendor';
import { enqueueSnackbar } from 'notistack';

export default function Row(props) {
  const {
    row,
    columns,
    secondaryColumns,

    colSpan = 6,
    isItemSelected = false,
    labelId = '',
    handleClick = () => null,
    enableCheckBox = true,
  } = props || {};

  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [quotedProjectData, setQuotedProjectdata] = useState([]);
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [suggestionData, setSuggestionData] = useState([]);
  useEffect(() => {
    getQuotedProjectDetails(id, row._id).then((res) => {
      setQuotedProjectdata([...res]);
    });
  }, [update]);

  const BlurredBackground = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.2,
    zIndex: 999,
  });

  const HandleAddRow = async () => {
    setLoading(true);
    await createEshopRequest({
      projectId: id,
      partId: row._id,
      currency: row?.currency?._id,
    }).then((response) => {
      setQuotedProjectdata([...quotedProjectData, response.eshop]);
      setLoading(false);
    });
    setLoading(false);
  };

  const HandleAddRowBySuggestion = async (entry) => {
    console.log(entry,"entryentry")
    setLoading(true);
    var body = {
      projectId: id,
      partId: entry?.partId,
      currency: row?.currency?._id,
      eshopName: entry?.eshopName,
      eshopLink: entry?.eshopLink,
      unit: entry?.quote?.unit,
      make: entry?.quote?.make,
      dataSheetFile: entry?.quote?.dataSheetFile,
      COC: entry?.quote?.COC,
      price: entry?.quote?.price,
      quantity: entry?.quote?.quantity,
      deliveryTimeline: entry?.quote?.deliveryTimeline,
      deliveryLeadTimeUnit: entry?.quote?.deliveryLeadTimeUnit,
    }

    await createEshopRequest(body).then((response) => {
      setQuotedProjectdata([...quotedProjectData, response.eshop]);
      setLoading(false);
    });
    setLoading(false);
  };


  const HandleSuggestion = async () => {
    setLoading(true);
    await getEshopRequestSuggestions(row._id)
      .then((response) => {
        console.log(response, 'response ashu');
        setSuggestionData(response);
        setIsModalOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(err?.data?.message, { variant: 'error' });
        console.log(err, 'Suggestion Api Error');
      });
    setLoading(false);
  };
  const HandleArchiveRequest = async (id) => {
    setLoading(true);
    await archiveVendorRequestService(id).then((response) => {
      setUpdate(update + 1);
      setQuotedProjectdata([]);
    });
    setLoading(false);
  };

  const HandleRemoveRow = async (id) => {
    setLoading(true);
    await deleteEshopRequest(id).then((response) => {
      setUpdate(update + 1);
      setQuotedProjectdata([]);
    });
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <BlurredBackground>
          <Backdrop open={loading} style={{ zIndex: 1000 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </BlurredBackground>
      )}
      <TableRow
        onClick={(event) => handleClick(event, row?.id)}
        aria-checked={isItemSelected}
        selected={isItemSelected}
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.code}
        sx={{ cursor: 'pointer' }}
      >
        <TableCell style={{ border: '1px solid gray' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <div onClick={() => setOpen(!open)}>
                <ExpandLess />
              </div>
            ) : (
              <div onClick={() => setOpen(!open)}>
                <ExpandMore />
              </div>
            )}
          </IconButton>
        </TableCell>
        {enableCheckBox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
        )}
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell
              onClick={() => setOpen(!open)}
              key={column.id}
              align="center"
              style={{ verticalAlign: 'top', textTransform: 'uppercase', border: '1px solid grey' }}
            >
              {column.render ? column.render(value, row) : value}
            </TableCell>
          );
        })}
        <TableCell
          align="center"
          style={{ verticalAlign: 'top', textTransform: 'uppercase', border: '1px solid grey' }}
        >
          <Button
            style={{ textTransform: 'uppercase' }}
            variant="outlined"
            href={`/quote/${id}/eshop/eshop-orders-delivery/${row?._id}`}
          >
            VIEW
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography textTransform="uppercase" variant="h6" gutterBottom component="div">
                Data
              </Typography>
              <Button variant="outlined" sx={{ my: 2 }} onClick={(e) => HandleAddRow()}>
                + ADD
              </Button>
              <Button variant="contained" sx={{ my: 2, ml: 4 }} onClick={(e) => HandleSuggestion()}>
                suggestions
              </Button>

              <Dialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                maxWidth="md"
                fullWidth
                sx={{
                  '& .MuiDialog-paper': {
                    backgroundColor: '#f5f5f5', // Light gray background
                  },
                }}
              >
                <DialogTitle>Data Details</DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={2}>
                    {suggestionData?.map((entry, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <Card
                          key={index}
                          sx={{
                            marginBottom: 2,
                            boxShadow: 6, // Use a higher shadow level to give a more 3D effect
                            transition: 'transform 0.3s, box-shadow 0.3s', // Smooth transition for hover effect
                            '&:hover': {
                              transform: 'translateY(-5px)', // Slight upward movement on hover
                              boxShadow: 12, // Stronger shadow on hover
                              backgroundColor: '#fcd4d2',
                            },
                          }}
                        >
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              Entry {index + 1}
                            </Typography>
                            <Typography>
                              <strong>Unit of Issue:</strong> {entry.quote.unit}
                            </Typography>
                            <Typography>
                              <strong>Make:</strong> {entry.quote.make}
                            </Typography>
                            <Typography>
                              <strong>Delivery Timeline:</strong> {entry.quote.deliveryTimeline}
                            </Typography>
                            <Typography>
                              <strong>Delivery Timeline Unit:</strong>{' '}
                              {entry.quote.deliveryLeadTimeUnit}
                            </Typography>
                            <Typography>
                              <strong>Data Sheet File:</strong> {entry.quote.dataSheetFile || 'N/A'}
                            </Typography>
                            <Typography>
                              <strong>COC:</strong> {entry.quote.COC || 'N/A'}
                            </Typography>
                            <Typography>
                              <strong>E-shop Name:</strong> {entry.eshopName}
                            </Typography>
                            <Typography>
                              <strong>E-shop Link:</strong>{' '}
                              <Link href={entry.eshopLink} target="_blank" rel="noopener">
                                {entry.eshopLink}
                              </Link>
                            </Typography>
                            <Typography>
                              <strong>Price:</strong> {entry.quote.currency.symbol}
                              {entry.quote.price}
                            </Typography>
                            <Typography>
                              <strong>Quantity:</strong> {entry.quote.quantity}
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Button
                              variant="contained"
                              sx={{ my: 2 }}
                              onClick={(e) => HandleAddRowBySuggestion(entry)}
                            >
                              + ADD
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setIsModalOpen(false)} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {secondaryColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          textTransform: 'uppercase',
                          border: '1px solid grey',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      style={{
                        position: 'sticky',
                        right: 0,
                        border: '1px solid grey',
                        //background: '#fff',
                        zIndex: 200,
                      }}
                    >
                      ACTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quotedProjectData.map(
                    (innerRow, index) =>
                      innerRow ? (
                        <CustomTableRow
                          id={id}
                          COLUMNS={secondaryColumns}
                          row={innerRow}
                          mainData={row}
                          setQuotedProjectdata={setQuotedProjectdata}
                          quotedProjectData={quotedProjectData}
                          index={index}
                          HandleRemoveRow={HandleRemoveRow}
                          HandleArchiveRequest={HandleArchiveRequest}
                        />
                      ) : null
                    // <TableRow hover role="checkbox" tabIndex={-1} key={innerRow.code}>
                    //   {secondaryColumns.map((column) => {
                    //     const value = innerRow[column.id];
                    //     return (
                    //       <TableCell
                    //         key={column.id}
                    //         align={column.align}
                    //         style={{ verticalAlign: 'top', paddingTop: 15, paddingBottom: 15 }}
                    //       >
                    //         {column.render ? column.render(value, innerRow) : value}
                    //       </TableCell>
                    //     );
                    //   })}
                    // </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const CustomTableRow = ({
  COLUMNS,
  row,
  mainData,
  id,
  quotedProjectData,
  setQuotedProjectdata,
  index,
  HandleRemoveRow,
  HandleArchiveRequest,
}) => {
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [approvalChanges, setApprovalChanges] = useState([]);
  const [errors, setErrors] = useState({});
  const [reason, setReason] = useState('');
  const [initialVal, setInitialVal] = useState({
    compliance: row?.Compliance || mainData?.Compliance,
    quantity: row?.quantity || row?.quote?.quantity || 0,
    HSN: row?.HSN || mainData?.HSN,
    COC: row?.COC || row?.quote?.COC ||'',
    unit: mainData?.unit || row?.quote?.unit|| '',
    make: row?.make || row?.quote?.make|| mainData.make,
    deliveryTimeline: row?.deliveryTimeline || row?.quote?.deliveryTimeline,
    deliveryLeadTimeUnit: row?.deliveryLeadTimeUnit || row?.quote?.deliveryLeadTimeUnit,
    price: row?.price || row?.quote?.price,
    eshopName: row?.eshopName,
    eshopLink: row?.eshopLink,
    vendorQuoteFile: mainData?.vendorQuoteFile || '',
    dataSheetFile: row?.dataSheetFile || row?.quote?.dataSheetFile|| '',
    description: mainData?.description || row?.description,
    isActive: row?.isActive,
    currency: row?.quote?.currency,
    _id: row?._id,
    isQuoteSubmitted: row?.isQuoteSubmitted,
  });
  const [values, setValues] = useState({
    compliance: row?.Compliance || mainData?.Compliance,
    quantity: row?.quantity || row?.quote?.quantity || 0,
    HSN: row?.HSN || mainData?.HSN,
    COC: row?.COC || row?.quote?.COC ||'',
    unit: mainData?.unit || row?.quote?.unit|| '',
    make: row?.make || row?.quote?.make|| mainData.make,
    deliveryTimeline: row?.deliveryTimeline || row?.quote?.deliveryTimeline,
    deliveryLeadTimeUnit: row?.deliveryLeadTimeUnit || row?.quote?.deliveryLeadTimeUnit,
    price: row?.price || row?.quote?.price,
    eshopName: row?.eshopName,
    eshopLink: row?.eshopLink,
    vendorQuoteFile: mainData?.vendorQuoteFile || '',
    dataSheetFile: row?.dataSheetFile || row?.quote?.dataSheetFile|| '',
    description: mainData?.description || row?.description,
    isActive: row?.isActive,
    currency: row?.quote?.currency,
    _id: row?._id,
    isQuoteSubmitted: row?.isQuoteSubmitted,
  });
  useEffect(() => {
    setApprovalChanges(
      row?.approvalCycles ? row?.approvalCycles[row?.approvalCycles?.length - 1] : []
    );
  }, [row]);

  const QuoteSchema = Yup.object().shape({
    quantity: Yup.number().min(1).required('quantity is required'),
    deliveryLeadTimeUnit: Yup.string().required('Required'),
    eshopName: Yup.string().required('Required'),
    eshopLink: Yup.string().required('Required'),
    deliveryTimeline: Yup.number('Enter number').min(1).required('Required'),
    price: Yup.number().min(0).required('price is required'),
    dataSheetFile: Yup.string(),
  });

  const handleUpdateValue = (fieldName, targetVal) => {
    setValues({ ...values, [fieldName]: targetVal });
  };

  const handleUpdateCurrencyPriceValue = (currencyId, price) => {
    setValues({ ...values, currency: currencyId, price: price });
  };
  console.log(values,"asfter update")

  const onSubmit = async (data) => {
    try {
      QuoteSchema.validateSync(values, { abortEarly: false });
      await updateEshopRequest(row?._id, { ...values, isActive: true });
      setValues({ ...values, isActive: true });
      setErrors({});
      enqueueSnackbar('Eshop Request Sent Successfully');
    } catch (error) {
      setErrors(
        error.inner.reduce((errors, err) => {
          errors[err.path] = err.message;
          return errors;
        }, {})
      );
      enqueueSnackbar('Error in creating Eshop Request!', { variant: 'error' });
    }
  };
  const updatedData = useDebounce(values);
  useEffect(() => {
    if (!values?.isActive) {
      updateEshopRequest(values?._id, { ...values, currency: values?.currency?._id });
    }
  }, [updatedData]);

  function findChanges(initial, updated) {
    let changes = {};
    let quoteTemp = row?.quote;
// console.log(updated,"ashuashuashu")
    for (let key in initial) {
      if (initial[key] !== updated[key]) {
        if (key === 'eshopLink' || key === 'eshopName') {
          changes[key] = updated[key];
        } else {
          quoteTemp[key] = updated[key];
        }
      }
    }
    changes.quote = quoteTemp;
    return changes;
  }

  const UpdateHandler = async () => {
    if (reason) {
      try {
        await QuoteSchema.validateSync(values, { abortEarly: false });
        let changedValues = findChanges(initialVal, values);
        initiateChangeApprovalEshop(values?._id, { changes: changedValues, reason: reason });
        enqueueSnackbar('Eshop Updated Request Raised Successfully');
        setErrors({});
      } catch (error) {
        setErrors(
          error.inner.reduce((errors, err) => {
            errors[err.path] = err.message;
            return errors;
          }, {})
        );
        enqueueSnackbar('Error in Updating Eshop Request!', { variant: 'error' });
      }
    } else {
      alert('PLEASE ENTER REASON');
    }
  };

  const ApproveChangesCycle = () => {
    if (reason) {
      try {
        approvalChangeApprovalEshop(values?._id, {
          response_flag: true,
          response_text: reason,
          userid: user?._id,
        });
        enqueueSnackbar('Eshop Request Updated Successfully');
        setErrors({});
      } catch (error) {
        setErrors(
          error.inner.reduce((errors, err) => {
            errors[err.path] = err.message;
            return errors;
          }, {})
        );
        enqueueSnackbar('Error in Updating Eshop Request!', { variant: 'error' });
      }
    } else {
      alert('PLEASE ADD APPROVAL REASON');
    }
  };

  // console.log(values,"ashuvalues")

  return (
    <StyledTableRow tabIndex={-1} key={row.code}>
      {COLUMNS.map((column) => {
        const value = row[column.id];
        return (
          <TableCell
            key={column.id}
            align={column.align}
            style={{ textTransform: 'uppercase', border: '1px solid grey' }}
          >
            <Box>
              <Box>{column.render ? column.render(values, handleUpdateValue, errors,handleUpdateCurrencyPriceValue) : values}</Box>
              <Box sx={{ color: '#CC0100' }}>
                {row?.isLocked &&
                  approvalChanges?.changes?.map((item) => {
                    if (item.fieldName === 'eshopLink' && column.id == 'eshopName') {
                      return <Box>UPDATE SOURCING FIELD ={item.newValue}</Box>;
                    } else if (item.fieldName === column.id && column.id == 'eshopName') {
                      return <Box>Update COMPANY NAME ={item.newValue}</Box>;
                    } else if (item.fieldName == 'quote' && column.id !== 'eshopName') {
                      if (item.newValue[column.id] !== item.oldValue[column.id]) {
                        console.log(item.newValue,"hey ashash")
                        if (column.id == 'COC' || column.id == 'dataSheetFile') {
                          return (
                            <Box sx={{ mt: 1 }} flex flexDirection="row">
                              Updated:{' '}
                              <UploadFile
                                label="datasheet"
                                value={item.newValue[column.id]}
                                disabled={true}
                              />
                            </Box>
                          );
                        } 
                        else if (column.id == 'price') {
                          return 'Update =' + item.newValue[column.id] +'    '+ item.newValue?.currency?.abbreviation;

                        }
                        else {
                          return 'Update =' + item.newValue[column.id];
                        }
                      }
                    }
                  })}
              </Box>
            </Box>
          </TableCell>
        );
      })}
      <TableCell
        style={{
          position: 'sticky',
          right: 0,
          background: '#fafafa',
          zIndex: 100,
          border: '1px solid grey',
          align: 'center',
        }}
      >
        {!values?.isActive ? (
          <>
            <Button
              style={{ textTransform: 'uppercase', mb: 2 }}
              variant="outlined"
              onClick={() => onSubmit(values)}
            >
              Submit
            </Button>

            <Button
              style={{ marginTop: 5, textTransform: 'uppercase', bg: '#CC0100', color: 'white' }}
              variant="contained"
              onClick={(e) => HandleRemoveRow(row?._id)}
            >
              Remove
            </Button>
          </>
        ) : row?.isLocked ? (
          <>
            <Button
              style={{ textTransform: 'uppercase', mb: 2 }}
              variant="contained"
              color="warning"
              disabled={values.isApproved}
              onClick={() => ApproveChangesCycle()}
            >
              Admin Aprrove changes
            </Button>
            <Typography sx={{ mt: 2, fontSize: 12, color: '#CC0100' }}>
              Reason:{' '}
              <Typography sx={{ mt: 2, color: 'black' }} variant="span">
                {approvalChanges?.submissionReasonText}
              </Typography>
            </Typography>
            <TextField
              label="Approval Reason"
              name={'reason'}
              size="small"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              sx={{ mb: 1.2, mt: 2, textTransform: 'uppercase' }}
            />
          </>
        ) : (
          <>
            <Button
              style={{ textTransform: 'uppercase', mb: 2 }}
              variant="outlined"
              onClick={() => UpdateHandler(values)}
            >
              Update
            </Button>
            <TextField
              label="Reason"
              name={'reason'}
              size="small"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              sx={{ mb: 1.2, mt: 2, textTransform: 'uppercase' }}
            />
          </>
        )}
        <Button
          style={{
            marginTop: 5,
            textTransform: 'uppercase',
            backgroundColor: '#26275c',
            color: 'white',
          }}
          variant="contained"
          onClick={(e) => HandleArchiveRequest(row?._id)}
        >
          Archive
        </Button>
      </TableCell>
    </StyledTableRow>
  );
};
